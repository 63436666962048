<template>
  <router-link class="flex justify-center items-center" to="/">
    <img :src="logoUrl" alt="Лого" :style="{ maxHeight }" />
  </router-link>
</template>

<script lang="ts">
export default { name: 'HeaderClientLogo' };
</script>

<script setup lang="ts">
import { useWindowSizeStore } from '@/store/pinia/window-size/window-size.store';
import { useRoute } from 'vue-router/composables';
import { computed, ref, watch } from 'vue';
import store from '@/store';

defineProps<{
  maxHeight: string;
}>();

const route = useRoute();
const windowSizeStore = useWindowSizeStore();

const clientInfo = computed(() => {
  return store.state.client.clientInfo;
});
const eventInfo = computed(() => {
  return store.state.event.eventInfo;
});

const customLogoUrl = ref<string | null>(null);
const customLogoMobileUrl = ref<string | null>(null);

const logoUrl = computed(() => {
  if (windowSizeStore.isWindow.mobile) {
    return customLogoMobileUrl.value
      ? customLogoMobileUrl.value
      : clientInfo.value?.header?.mobileLogo;
  } else {
    return customLogoUrl.value ? customLogoUrl.value : clientInfo.value?.logoUrl;
  }
});

const routesWithCustomLogo = ['EventPage', 'EventProgramPage'];
watch(
  () => [route.name, eventInfo.value?.customLogo, eventInfo.value?.customLogoMobile],
  ([newRouteName, newCustomLogoUrl, newCustomLogoMobileUrl]) => {
    const isRouteWithCustomLogo = routesWithCustomLogo.includes(newRouteName);
    if (isRouteWithCustomLogo) {
      customLogoUrl.value = newCustomLogoUrl ? newCustomLogoUrl : null;
      customLogoMobileUrl.value = newCustomLogoMobileUrl ? newCustomLogoMobileUrl : null;
    } else {
      customLogoUrl.value = null;
      customLogoMobileUrl.value = null;
    }
  },
  { immediate: true }
);
</script>

<style scoped lang="scss"></style>

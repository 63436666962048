<template>
  <div>
    <div class="layout">
      <header id="admin-header">
        <AdminHeader />
      </header>

      <main :style="{ backgroundColor: mainBackgroundColor }">
        <router-view />
      </main>

      <AdminSidebar v-if="$route.meta?.['sidebar']" />

      <footer id="admin-footer">
        <AdminFooter />
      </footer>
    </div>
  </div>
</template>

<script lang="ts">
export default { name: 'AdminLayout' };
</script>

<script setup lang="ts">
import AdminSidebar from '@/layouts/admin/AdminSidebar.vue';
import AdminHeader from '@/layouts/admin/AdminHeader.vue';
import AdminFooter from '@/layouts/admin/AdminFooter.vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router/composables';
import { tailwindTheme } from '@/helpers/configs/tailwind-theme.config';
import Footer from '@/layouts/Footer/Footer.vue';

const route = useRoute();

const grayBackgroundPages = [
  'UserManagementEventUsers',
  'UserManagementInvitedUsers',
  'UserManagementFullStatistics',
  'AdminPartners',
  'EventSettings',
];
const mainBackgroundColor = computed(() => {
  const isGrayBackground = grayBackgroundPages.includes(String(route.name));
  return isGrayBackground ? tailwindTheme.colors.neutral['100'] : 'inherit';
});
</script>

<style lang="scss"></style>

import { ISpeakerCardInfo } from '@/services/EventService/EventServiceTypes';

export const DEFAULT_SPEAKER_INFO: ISpeakerCardInfo = {
  image: '',
  email: '',
  name: '',
  jobTitle: '',
  description: '',
};
export const DEFAULT_SPEAKER_IMAGE_SCALE = 1;

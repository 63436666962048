var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"box-border bg-white"},[(_setup.windowSizeStore.isWindow.desktop)?_c('div',{staticClass:"container pt-5 pb-10 flex flex-col gap-6"},[_c('div',{staticClass:"flex",class:{
        'justify-between': _vm.navItems.length,
        'justify-center': !_vm.navItems.length,
      }},[_c('div',[_c(_setup.HeaderClientLogo,{attrs:{"max-height":"46px"}})],1),(_vm.navItems.length)?_c('div',{staticClass:"flex items-center gap-5"},_vm._l((_vm.navItems),function(navItem){return _c(_setup.NavItem,{key:navItem.name,attrs:{"nav-item":navItem,"font-weight":"400"}})}),1):_vm._e()]),_c('div',{staticClass:"flex justify-between items-center"},[_c('div',{staticClass:"flex items-center gap-5"},[_c('img',{staticClass:"h-[16px]",attrs:{"src":require("../../assets/images/shared/mmco-logo/mmco-logo.svg"),"alt":"Логотип MMCO"}}),_c('div',{staticClass:"text-sm text-gray"},[_vm._v(_vm._s(_setup.FOOTER_COPYRIGHT))])]),(_setup.userAgreement)?_c('a',{staticClass:"default-footer-link",attrs:{"href":_setup.userAgreement.fileUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(_setup.userAgreement.name)+" ")]):_vm._e(),(_setup.privacyPolicy)?_c('a',{staticClass:"default-footer-link",attrs:{"href":_setup.privacyPolicy.fileUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(_setup.privacyPolicy.name)+" ")]):_vm._e()])]):_vm._e(),(!_setup.windowSizeStore.isWindow.desktop)?_c('div',{staticClass:"flex flex-col p-5 gap-6"},[_c('div',{staticClass:"w-full h-[1px] bg-neutral-200"}),_c('div',{staticClass:"flex",class:{
        'justify-start': _vm.navItems.length,
        'justify-center': !_vm.navItems.length,
      }},[_c(_setup.HeaderClientLogo,{attrs:{"max-height":"40px"}})],1),(_vm.navItems.length)?_c('div',{staticClass:"flex flex-wrap gap-6"},_vm._l((_vm.navItems),function(navItem){return _c(_setup.NavItem,{key:navItem.name,attrs:{"nav-item":navItem,"font-weight":"500"}})}),1):_vm._e(),_c('div',{staticClass:"w-full h-[1px] bg-neutral-200"}),_c('div',{staticClass:"flex flex-col gap-2.5"},[_vm._m(0),_c('div',{staticClass:"text-sm text-gray"},[_vm._v(_vm._s(_setup.FOOTER_COPYRIGHT))]),(_setup.userAgreement)?_c('a',{staticClass:"default-footer-link",attrs:{"href":_setup.userAgreement.fileUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(_setup.userAgreement.name)+" ")]):_vm._e(),(_setup.privacyPolicy)?_c('a',{staticClass:"default-footer-link",attrs:{"href":_setup.privacyPolicy.fileUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(_setup.privacyPolicy.name)+" ")]):_vm._e()])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex justify-start"},[_c('img',{staticClass:"h-[16px]",attrs:{"src":require("../../assets/images/shared/mmco-logo/mmco-logo.svg"),"alt":"Логотип MMCO"}})])
}]

export { render, staticRenderFns }
<template>
  <div class="speaker-form" :class="[{ 'with-err': isErrorForm }]" id="speakerForm">
    <div v-if="isShowEditor" class="speaker-form__editor-wrapper">
      <div class="speaker-form__slider">
        <AppInputSlider @onInput="form.imageScale = $event" />
      </div>
      <div class="speaker-form__editor-controls">
        <!--        @select-file=""-->
        <VueAvatar
          :width="300"
          :height="300"
          :rotation="0"
          :scale="form.imageScale"
          :border="0"
          :image="form.imageArrayBuffer"
          class="speaker-form__avatar-editor"
          ref="vueAvatarRef"
          @vue-avatar-editor:image-ready="form.imageScale = 1"
          @select-file="form.imageArrayBuffer = vueAvatarRef.getImageScaled().toDataURL()"
        />
        <AppButtonIcon name="x" color="#fff" icon-size="12" @click="removeImage" />
      </div>
    </div>
    <input
      id="add-avatar-file"
      type="file"
      name="add-avatar-file"
      :accept="['image/jpg, image/jpeg, image/png']"
      v-show="false"
      ref="imageInputRef"
      @change="createImageArrayBuffer"
    />
    <AppButton
      v-if="!isShowEditor"
      size="small"
      type="secondary"
      text="Добавить фото пользователя"
      fontType="primary-book"
      @click="clickImageInput"
    >
      <template #prepend>
        <div class="speaker-form__image-icon">
          <AppIcon name="big_image" size="18" />
        </div>
      </template>
    </AppButton>

    <AppInput
      type="email"
      placeholder="Введите e-mail"
      label="* E-mail"
      :value="form.email"
      @onInput="form.email = $event"
      :errors="getErrors('email')"
    />

    <div class="speaker-form__speaker-row">
      <AppInput
        placeholder="Введите имя"
        label="* Имя"
        :value="form.firstName"
        @onInput="form.firstName = $event"
        :errors="getErrors('firstName')"
      />
      <AppInput
        placeholder="Введите фамилию"
        label="* Фамилия"
        :value="form.lastName"
        @onInput="form.lastName = $event"
        :errors="getErrors('lastName')"
      />
    </div>

    <AppInput
      placeholder="Введите отчество"
      label="Отчество"
      :value="form.middleName"
      @onInput="form.middleName = $event"
    />

    <AppInput
      placeholder="Введите место работы"
      label="* Место работы"
      :value="form.description"
      @onInput="form.description = $event"
      :errors="getErrors('description')"
    />

    <AppInput
      placeholder="Введите должность"
      label="* Должность"
      :value="form.jobTitle"
      @onInput="form.jobTitle = $event"
      :errors="getErrors('jobTitle')"
    />

    <div class="speaker-form__buttons">
      <AppButton
        text="Сохранить"
        type="primary"
        size="small"
        fontType="primary-book"
        @click="save"
        :is-disabled="!canSave"
      />
      <AppButtonIcon
        class="speaker-form__add-button"
        name="x"
        icon-size="16"
        @click="$emit('close')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import { AppInput, AppInputSlider, AppButton, AppButtonIcon } from '@/ui/components';
import AppIcon from '@/ui/components/icons/app-icon/AppIcon.vue';
import { VueAvatar } from 'vue-avatar-editor-improved';
import EX_Toaster from '@/classes/content/toast';
import { ISpeakerCardInfo } from '@/services/EventService/EventServiceTypes';
import {
  DEFAULT_SPEAKER_IMAGE_SCALE,
  DEFAULT_SPEAKER_INFO,
} from '@/helpers/constants/admin/speaker-form.constants';
import { email, helpers, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { usernameJoiner, usernameSplitter } from '@/helpers/methods/username-splitter.method';
import store from '@/store';

export default defineComponent({
  name: 'EditProgramSpeakerForm',
  components: {
    AppInput,
    AppButton,
    AppButtonIcon,
    AppIcon,
    AppInputSlider,
    VueAvatar,
  },
  emits: ['save', 'close'],
  props: {
    speakerInfo: {
      type: Object as PropType<ISpeakerCardInfo>,
      required: false,
      default: () => DEFAULT_SPEAKER_INFO,
    },
    uuid: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { firstName, lastName, middleName } = usernameSplitter(props.speakerInfo.name);

    const form = ref({
      image: props.speakerInfo.image,
      imageScale: DEFAULT_SPEAKER_IMAGE_SCALE,
      imageArrayBuffer: props.speakerInfo.image as string | ArrayBuffer,
      email: props.speakerInfo.email,
      firstName,
      lastName,
      middleName,
      description: props.speakerInfo.description,
      jobTitle: props.speakerInfo.jobTitle,
    });
    const rules = {
      image: {},
      imageScale: {},
      imageArrayBuffer: {},
      email: {
        required: helpers.withMessage('Обязательное поле', required),
        email: helpers.withMessage('Введите корректный email', email),
      },
      firstName: {
        required: helpers.withMessage('Обязательное поле', required),
      },
      lastName: {
        required: helpers.withMessage('Обязательное поле', required),
      },
      middleName: {},
      description: {
        required: helpers.withMessage('Обязательное поле', required),
      },
      jobTitle: {
        required: helpers.withMessage('Обязательное поле', required),
      },
    };
    const v$ = useVuelidate(rules, form, { $autoDirty: true });
    const getErrors = (key: keyof typeof form.value) => {
      return v$.value[key].$errors.map((item) => item.$message);
    };

    const isShowEditor = computed(() => {
      return form.value.image || form.value.imageArrayBuffer;
    });

    const imageInputRef = ref<HTMLInputElement | null>(null);
    const clickImageInput = () => {
      imageInputRef.value?.click();
    };
    const removeImage = () => {
      if (form.value.image) form.value.image = '';
      if (form.value.imageArrayBuffer) form.value.imageArrayBuffer = '';
      if (form.value.imageScale !== DEFAULT_SPEAKER_IMAGE_SCALE)
        form.value.imageScale = DEFAULT_SPEAKER_IMAGE_SCALE;

      if (imageInputRef.value) imageInputRef.value.value = '';
    };

    const createImageArrayBuffer = (event: Event) => {
      event.preventDefault();
      let target = event.target as HTMLInputElement;
      let files = target.files || [];
      if (files.length) {
        const reader = new FileReader();
        reader.onload = (e) => {
          form.value.imageArrayBuffer = e.target?.result ?? '';
        };
        reader.readAsDataURL(files[0]);
      }
    };

    const vueAvatarRef = ref();
    const saveImage = async () => {
      const imageScaled = vueAvatarRef.value?.getImageScaled();
      const isDirtyImage = v$.value.imageArrayBuffer.$dirty || v$.value.imageScale.$dirty;
      if (!isDirtyImage || !imageScaled) return;
      try {
        const blob: Blob = await new Promise((resolve) =>
          imageScaled.toBlob((res: Blob) => {
            resolve(res);
          })
        );
        const formData = new FormData();
        formData.append('image', blob);
        form.value.image = await store.dispatch('admin/AUploadAvatarImage', formData);
      } catch (e) {
        EX_Toaster.error('Произошла ошибка при сохранении изображения');
      }
    };
    const canSave = computed(() => {
      return v$.value.$anyDirty && !v$.value.$invalid;
    });
    const save = async () => {
      if (!canSave.value) return;
      await saveImage();

      try {
        const info = {
          image: form.value.image,
          email: form.value.email,
          name: usernameJoiner(form.value.firstName, form.value.lastName, form.value.middleName),
          jobTitle: form.value.jobTitle,
          description: form.value.description,
        };
        const data = {
          uuid: props.uuid,
          info: info,
        };
        const res = props.uuid
          ? await store.dispatch('admin/AEditSpeakerClient', data)
          : await store.dispatch('admin/ACreateSpeakerClient', { info });

        EX_Toaster.success('Спикер успешно сохранён');
        emit('save', res);
      } catch (e) {
        EX_Toaster.error('Произошла ошибка при сохранении спикера');
      }
    };

    const isErrorForm = computed(() => {
      return props.uuid && v$.value.$anyDirty && v$.value.$invalid;
    });

    return {
      form,
      v$,
      getErrors,
      isShowEditor,
      imageInputRef,
      clickImageInput,
      removeImage,
      createImageArrayBuffer,
      vueAvatarRef,
      canSave,
      save,
      isErrorForm,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '~@/ui/styles/colors/index';
.speaker-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid #6868cb;
  &.with-err {
    border: 2px solid $CHINESE-RED;
  }
  &__buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .button {
      width: max-content;
      padding-left: 16px;
      padding-right: 16px;
      max-height: 36px;
      border-radius: 8px;
    }
  }
  &__speaker-row {
    display: flex;
    gap: 20px;
  }
  &__add-button {
    height: 36px;
    width: 36px;
    border-radius: 8px;
    padding: 8px 16px;
    border: 1px solid #dedede;
    &:hover {
      background-color: $SMOKY-WHITE;
    }
    /* width: 100%; */
  }
  &__slider {
    height: 100%;
  }
  &__image-icon {
    padding-right: 8px;
  }
  &__avatar-editor {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background-color: white;
    border: 2px solid #6868cb;
    overflow: hidden;
  }
  &__editor-controls {
    position: relative;
    .app-button-icon {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      position: absolute !important;
      background: #83888f !important;
      right: -4px;
      top: -10px;
      padding-left: 1px;
      box-sizing: border-box;
      border: 2px solid white;
    }
    .button {
      position: absolute;
      width: 100px;
      height: 36px;
      border-radius: 8px;
      right: 10px;
      bottom: 10px;
    }
  }
  &__editor-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}
</style>

<template>
  <div class="flex flex-col">
    <!--    <BannerAdsPageCustomization-->
    <!--      v-if="isShowBannerAdvertisement"-->
    <!--      :advertisement="$store.state.event.eventInfo?.pageCustomization?.advertisement"-->
    <!--    />-->
    <!--    <BannerAdsMmcoTechPartner v-if="isShowBannerMmco" />-->
    <FooterBase v-if="variant === 'BASE'" :nav-items="navItems" />
  </div>
</template>

<script lang="ts">
export default { name: 'Footer' };
</script>

<script setup lang="ts">
import FooterBase from '@/layouts/Footer/FooterBase.vue';
import BannerAdsPageCustomization from '@/components/shared/banners/ads/BannerAdsPageCustomization.vue';
import BannerAdsMmcoTechPartner from '@/components/shared/banners/ads/BannerAdsMmcoTechPartner.vue';
import { FooterVariant } from '@/helpers/types/layouts.types';
import { computed } from 'vue';

const variant = computed<FooterVariant>(() => {
  return 'BASE';
});
const navItems = computed(() => {
  return [];
});
</script>

<style scoped lang="scss"></style>
